// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-email-verification-js": () => import("./../../../src/pages/email-verification.js" /* webpackChunkName: "component---src-pages-email-verification-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-detail-add-to-cart-segment-index-js": () => import("./../../../src/pages/ProductDetail/AddToCartSegment/index.js" /* webpackChunkName: "component---src-pages-product-detail-add-to-cart-segment-index-js" */),
  "component---src-pages-product-detail-faq-index-js": () => import("./../../../src/pages/ProductDetail/Faq/index.js" /* webpackChunkName: "component---src-pages-product-detail-faq-index-js" */),
  "component---src-pages-product-detail-index-js": () => import("./../../../src/pages/ProductDetail/index.js" /* webpackChunkName: "component---src-pages-product-detail-index-js" */),
  "component---src-pages-product-detail-option-item-panel-index-js": () => import("./../../../src/pages/ProductDetail/OptionItemPanel/index.js" /* webpackChunkName: "component---src-pages-product-detail-option-item-panel-index-js" */),
  "component---src-pages-product-detail-option-panel-control-type-1-index-js": () => import("./../../../src/pages/ProductDetail/OptionPanelControlType1/index.js" /* webpackChunkName: "component---src-pages-product-detail-option-panel-control-type-1-index-js" */),
  "component---src-pages-product-detail-option-panel-control-type-2-index-js": () => import("./../../../src/pages/ProductDetail/OptionPanelControlType2/index.js" /* webpackChunkName: "component---src-pages-product-detail-option-panel-control-type-2-index-js" */),
  "component---src-pages-product-detail-option-panel-control-type-3-index-js": () => import("./../../../src/pages/ProductDetail/OptionPanelControlType3/index.js" /* webpackChunkName: "component---src-pages-product-detail-option-panel-control-type-3-index-js" */),
  "component---src-pages-product-detail-option-panel-control-type-4-index-js": () => import("./../../../src/pages/ProductDetail/OptionPanelControlType4/index.js" /* webpackChunkName: "component---src-pages-product-detail-option-panel-control-type-4-index-js" */),
  "component---src-pages-product-detail-option-panel-index-js": () => import("./../../../src/pages/ProductDetail/OptionPanel/index.js" /* webpackChunkName: "component---src-pages-product-detail-option-panel-index-js" */),
  "component---src-pages-product-detail-product-header-panel-index-js": () => import("./../../../src/pages/ProductDetail/ProductHeaderPanel/index.js" /* webpackChunkName: "component---src-pages-product-detail-product-header-panel-index-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-campaign-detail-page-js": () => import("./../../../src/templates/campaign-detail-page.js" /* webpackChunkName: "component---src-templates-campaign-detail-page-js" */),
  "component---src-templates-campaign-page-js": () => import("./../../../src/templates/campaign-page.js" /* webpackChunkName: "component---src-templates-campaign-page-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-cookie-policy-js": () => import("./../../../src/templates/cookie-policy.js" /* webpackChunkName: "component---src-templates-cookie-policy-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-member-permission-text-js": () => import("./../../../src/templates/member-permission-text.js" /* webpackChunkName: "component---src-templates-member-permission-text-js" */),
  "component---src-templates-permission-text-js": () => import("./../../../src/templates/permission-text.js" /* webpackChunkName: "component---src-templates-permission-text-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-razer-gold-campaign-js": () => import("./../../../src/templates/razer-gold-campaign.js" /* webpackChunkName: "component---src-templates-razer-gold-campaign-js" */),
  "component---src-templates-survey-page-js": () => import("./../../../src/templates/survey-page.js" /* webpackChunkName: "component---src-templates-survey-page-js" */)
}

